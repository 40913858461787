import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import cx from 'classnames';
import FormSectionHeader from '../FormSectionHeader';
import Tooltip from '../Tooltip';
import countryReqs from '../../../country_reqs.json';

let countryCodesToSkipIdVerification = ['US', 'CA'] // don't include national id and type when verifying identity

// Preprocess requirements to collect which countries Plaid supports Lightning verification but can't verify national ID
const countryCodesThatSupportNationalId = countryReqs
  .filter(countryEntry =>  countryEntry.id_numbers.length)
  .map(countryEntry => countryEntry.country.code)

// Preprocess requirements JSON to convert id number regex from Ruby to ECMAScript
countryReqs.forEach(entry => {
  entry?.id_numbers?.forEach(idNumber => {
    if (idNumber?.validation?.pattern) {
      idNumber.validation.pattern = idNumber.validation.pattern
        .replaceAll(/\\A/g, '^')
        .replaceAll(/\\z/g, '$');
    }
  });
});

function NationalIdInput({ control, countryCode, isMobile, errors }) {
  const [selectedIdType, setSelectedType] = useState(null);
  const [ruleForCountry, setRuleForCountry] = useState(null);

  // Get rule object for a given country code
  function getRuleForCountry(code) {
    return countryReqs.find(countryReq => countryReq.country.code === code);
  }

  // Update ruleForCountry whenever countryCode changes
  useEffect(() => {
    const rule = getRuleForCountry(countryCode);
    setRuleForCountry(rule);
    setSelectedType(null); // Reset selected ID type when country code changes
  }, [countryCode]);

  const onChangeIdType = selectedOption => {
    setSelectedType(selectedOption);
  };

  function getOptions() {
    let rules = [];
    if (ruleForCountry?.id_numbers?.length) {
      rules = ruleForCountry.id_numbers.map(idType => ({
        value: idType.type,
        label: idType.name,
      }));
    }
    return rules
  }

  // Don't attempt to collect national ID for countries Plaid doesn't support or countries we exclude (US or Canada)
  if (!countryCodesThatSupportNationalId.includes(countryCode) || countryCodesToSkipIdVerification.includes(countryCode)) {
    return null;
  }

  return (
    <>
      <FormSectionHeader
        title="ID Verification"
        rightNode={(
          <Tooltip
            id="id-verification-explainer"
            label={'Why do we collect this?'}
            tooltipClassName="signup-tooltip"
          >
            <p>
              <strong>Security and Fraud Prevention:</strong>  Identification number is used to verify your identity, which ensures the integrity of financial transactions made on our platform.
            </p>
          </Tooltip>
        )}
        isMobile={isMobile}
      />
      <div className='form-group'>
        <Controller
          name="nationalId"
          control={control}
          rules={{
            required: 'National ID is required',
            validate: value => {
              if (!selectedIdType) return 'Please select an ID type.';
              const selectedObject = ruleForCountry?.id_numbers.find(idType => idType.type === selectedIdType.value);

              if (selectedObject) {
                const pattern = new RegExp(selectedObject.validation.pattern);
                if (!pattern.test(value.idNumber)) {
                  return `Invalid ${selectedObject.name}. Ex: ${selectedObject.example}`;
                }
              }
              return true;
            }
          }}
          render={({ field }) => (
            <>
              <div className='form-field'>
                <Select
                  options={getOptions()}
                  className={cx('form-field', 'select', 'mid')}
                  value={selectedIdType}
                  onChange={selectedOption => {
                    onChangeIdType(selectedOption);
                    field.onChange({
                      ...field.value,
                      idType: selectedOption.value,
                    });
                  }}
                  placeholder="Select ID Type"
                />
              </div>

              <div
                className={cx('form-field', {
                  'error': errors.nationalId,
                })}
              >
                <input
                  type="text"
                  placeholder={'ID Number'}
                  value={field.value?.idNumber || ''}
                  onChange={e => {
                    field.onChange({
                      ...field.value,
                      idNumber: e.target.value,
                    });
                  }}
                  disabled={!selectedIdType}
                />
                {errors.nationalId && <span className="error">{errors.nationalId.message}</span>}
              </div>
            </>
          )}
        />
      </div>
    </>
  );
}

export default NationalIdInput;
