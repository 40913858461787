import React from 'react';
import Select from 'react-select';
import { CountryRegionData } from 'react-country-region-selector';
import countryReqs from '../../../country_reqs.json';

// prefilter the Plaid country data to only those with support for Lightning
// @note despite the key being 'id_number' this field actually indicates which countries they offer Lightning verification
// there are some countries set to 'required' that Plaid can't actually verify national ID
const supportedCountryCodes = countryReqs
    .filter(entry => entry?.input_validation.id_number !== 'not_supported')
    .map(entry => entry.country.code);

function getCountryOptions() {
    // Pre-filter the list of countries to only those where Plaid supports Lightning IDV
    const filteredCountryRegionData = CountryRegionData.filter(country => supportedCountryCodes.includes(country[1]));

    const preferredOptions = ['US', 'CA', 'GB', 'AU', 'FR', 'DE', 'IT', 'HK', 'CZ', 'MX'];

    // Sort the options array to move preferred options to the top
    const sortedOptions = [
        ...preferredOptions.map(option => filteredCountryRegionData.find(country => country[1] === option)),
        ...filteredCountryRegionData.filter(country => !preferredOptions.includes(country[1]))
    ];

    return sortedOptions;
}

const supportedCountryOptions = getCountryOptions();

function CountrySelector({ value, onChange, onFocus, onBlur, className }) {
    return (
        <Select
            options={supportedCountryOptions.map(country => ({
                value: country[1],
                label: country[0]
            }))}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            className={className}
            placeholder="Select country..."
            defaultValue={{ value: 'US', label: 'United States' }}
        />
    );
}

export default CountrySelector;